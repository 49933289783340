.wall-posts {
    background: rgb(1, 111, 230);
    background: linear-gradient(
      22deg,
      rgba(1, 111, 230, 0.5192629815745393) 0%,
      rgb(238, 238, 247) 40%,
      rgb(243, 226, 226) 60%,
      rgba(255, 0, 0, 0.44861694677871145) 100%
    );
    min-height: 96vh;
}
#search-box-col {
  height: 100px;
}
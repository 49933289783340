.header-logo {
    height: 70px;
    
}
.header {
    width: 100%;
    // background-color: #F8F9FA;
}
#brand {
    width: 40%;
    // font-size: medium;
}
@media only screen and (max-width: 765px) {
    
    #brand {
        width: 75%;
        font-size: medium;
    }

}

@media only screen and (max-width: 1400px) {
    .header-links {
        font-size: small;
        // font-weight: 700;
    }
    
}



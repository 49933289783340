.post-img {
  max-height: 200px;
 
  width: 100%;
  object-fit: scale-down;
}

.body-text {
  min-height: 120px;

  max-height: 170px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    transform: scale(1.03);
    cursor: pointer
  }
}

.party-logo {
  height: 30px;
}
@media only screen and (max-width: 765px) {
  .title-line {
    text-align: center;
  }
}

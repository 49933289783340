
.App {
    font-family: 'Montserrat', sans-serif;
}

.pointer:hover {
    cursor: pointer;
}
.underline:hover {
text-decoration: underline;
}
.hover-blue:hover {
color: blue;
}
#file-iframe {
    width: 100%;
    height: 100%;
}
#donate-button-link {
    border: none;
    padding: 0;
    background-color: transparent;
    color: #0d6efd;
}
#donate-button-link:hover {
    text-decoration: underline;
}

.checkbox-or-spinner {
    display: inline;
    margin-right: 20px;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
  }
 .border-selected {
    border: 1px solid #0d6efd;
    border-radius: 5px;
    box-shadow: 0 0 5px #0d6efd;
 }
.landing-container {
  min-height: 100vh;
}
.landing-img {
  height: auto;
  width: 100%;
}

.intro-row {
  background-image: url("https://s3.amazonaws.com/libapps/accounts/101468/images/US-Constitution.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left;
}
.intro-col {
 animation: 1s ease-out 0s 1 startToEnd forwards;
}

@keyframes startToEnd {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(103%);
  }
}

@media screen and (max-width: 765px) {
  .intro-col {
    animation: none;
  }
}
